import { FC } from 'react';
import IconProps from './IconProps';

const DragHandleIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="2" cy="18" rx="2" ry="2" fill="currentColor" />
      <ellipse cx="2" cy="10" rx="2" ry="2" fill="currentColor" />
      <ellipse cx="2" cy="2" rx="2" ry="2" fill="currentColor" />
      <ellipse cx="10" cy="18" rx="2" ry="2" fill="currentColor" />
      <ellipse cx="10" cy="10" rx="2" ry="2" fill="currentColor" />
      <ellipse cx="10" cy="2" rx="2" ry="2" fill="currentColor" />
    </svg>
  );
};

export default DragHandleIcon;
